module.exports = {
  baseCookieExpire: 7776000, // 90 days
  cookies: {
    locale: 'SECOUNTRYCODE',
    geolocated: 'geolocated',
    countrySelected: 'country_selected',
  },
  demandBaseAPIDataKey: 'STATDEMANDBASE',
  geolocationAPIRetryInterval: 300,
  geolocationAPIRetryLimit: 10,
  getLocaleByCountryCodeEndpoint: '/ww/en/api/languagecodes/countryCode',
  globalLocaleCookiesList: [
    'wwen',
    'wwfr',
  ],
  setCookieKeywords: {
    geolocated: 'geolocated',
    countrySelect: 'countrySelect',
    locale: 'locale',
  },
};
